/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
  if (window.location.pathname === '/' && window.location.hash === '') {
    const homeSplash = document.querySelector('#homeSplash')
    document.body.style.position = 'fixed'
    document.body.style.overflowY = 'scroll'
    homeSplash.style.opacity = 100
    homeSplash.style.zIndex = 50
    setTimeout(() => {
      homeSplash.style.transition = 'opacity 500ms'
      homeSplash.style.opacity = 0
      document.body.style.position = 'static'
      setTimeout(() => {
        homeSplash.style.display = 'none'
        homeSplash.style.zIndex = -1
        document.body.style.overflowY = 'auto'
      }, 500)
    }, 1000)
  }
}

exports.shouldUpdateScroll = ({
  routerProps: {
    location: { hash },
  },
}) => {
  if (hash) {
    setTimeout(() => {
      window.scrollTo(0, document.querySelector(hash).offsetTop)
    }, 0)
    return false
  }
  return false
}
